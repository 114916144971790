import React from 'react';

import './Card27.css';

const Card27 = props => {
  return (
    <div className={`card27 ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default Card27;
