import React from 'react';

import './Card30.css';

const Card30 = props => {
  return (
    <div className={`card30 ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default Card30;
