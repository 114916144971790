import React, { useState, useEffect } from "react";
import { getWeb3, getContracts } from './utils.js';
import App from '../App.js';
import Card21 from '../shared/components/UIElements/Card21';
import Card11 from '../shared/components/UIElements/Card11';
import football from '../images/football2.svg';


function LoadingContainer2() {
  const [web3, setWeb3] = useState(undefined);
  const [accounts, setAccounts] = useState([]);
  const [contracts, setContracts] = useState(undefined);
  const [admin, setAdmin] = useState(undefined);
  // const [admin2, setAdmin2] = useState(undefined);

  useEffect(() => {
    const init = async () => {
      const web3 = await getWeb3();
      const contracts = await getContracts(web3);
      const accounts = await web3.eth.getAccounts();
      const admin = await contracts.sportsClub.methods
        .admin()
        .call();
      // const admin2 = await contracts.marketplace.methods
      //   .AccessAddresses(1)
      //   .call();
      // const admin3 = await contracts.marketplace.methods
      //   .AccessAddresses(3)
      //   .call();
      setWeb3(web3);
      setContracts(contracts);
      setAccounts(accounts);
      setAdmin(admin);
      // setAdmin2(admin2);
    }
    init();
  // eslint-disable-next-line
  }, []);

  // const onConnect = async() => {
  //   try {
  //     const currentProvider = detectCurrentProvider();
  //     if(currentProvider) {
  //       await currentProvider.request({method: 'eth_requestAccounts'});
  //       const web3 = new Web3(currentProvider);
  //       const userAccount  =await web3.eth.getAccounts();
  //       const account = userAccount[0];
  //       let ethBalance = await web3.eth.getBalance(account);
  //       setEthBalance(ethBalance);
  //       setIsConnected(true);
  //     }
  //   } catch(err) {
  //     console.log(err);
  //   }
  // }

  // const onDisconnect = () => {
  //   setIsConnected(false);
  // }

  function connect() {
    getWeb3()
  }

  // does not load if admin1 and admin2 is not explicitly stated
  function isReady() {
    return (
      typeof web3 !== 'undefined'
      && typeof contracts !== 'undefined'
      && typeof admin !== 'undefined'
      // && typeof admin2 !== 'undefined'
      && accounts.length > 0
    );
  }
  if (!isReady()) {
        return (
        <div className='logo4-wrapper' > 
        
        {/* <img src={football} alt=""/> */}
{/* <Card21 className="sponsor-item__content">
    <Card11 card-info11>
    <>

      <h1>Please change to BNB chain And Connect!!!!</h1>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          window.location.href='https://chainlist.org/chain/56/';
        }}
        className="button"
    > Add BNB Chain</button>
        </>
  </Card11> 
   </Card21> */}
   </div>
    )
  }

  return (
    <App
      web3={web3}
      accounts={accounts}
      contracts={contracts}
      admin={admin}
      // admin2={admin2}
    />
  );
}

export default LoadingContainer2;
