import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import Users from './user/pages/Users';
// import NewPlace from './places/pages/NewPlace';
// import UserPlaces from './places/pages/UserPlaces';
// import UpdatePlace from './places/pages/UpdatePlace';
// import Auth from './user/pages/Auth';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
// import UserManagers from './places/pages/UserManagers';
// import NewManager from './places/pages/NewManager';

// import { Drizzle } from '@drizzle/store';
// import { drizzleReactHooks } from "@drizzle/react-plugin";
// import drizzleOptions from "./TokenProduct/drizzleOptions";
// import LoadingContainer from './TokenProduct/LoadingContainer';
import SponsorProduct from './TokenProduct/SponsorProduct';
import CreateTokens from './TokenProduct/CreateTokens';
import MyNFTs from './TokenProduct/MyNFTs.js';
import ManageTokens from './TokenProduct/ManageTokens.js';
import MarkplaceFunction from './TokenProduct/MarkplaceFunction.js';
import Fantasy from './TokenProduct/Fantasy';
import NFTItems from './TokenProduct/NFTItems';
import { SportsDataProvider } from './TokenProduct/SportsDataContext';

// const Users = React.lazy(() => import('./user/pages/Users'));
// const drizzle = new Drizzle(drizzleOptions);
// const { DrizzleProvider } = drizzleReactHooks;



const App = ({web3, accounts, contracts, admin, tokenIds}) => {


  let routes;


    routes = (
      <div>
        <Route path="/" exact>
              {/* Make new Page and have all team with points balance on front page */}
              {/* <TeamNFTs/> */}
            <SportsDataProvider contracts={contracts} tokenIds={tokenIds}>
              {/* ... other components */}
              {/* Any child component here will be able to access the shared state */}
            <Users /> 
            </SportsDataProvider>              
              </Route>
          <Route path="/Teams" exact>
              <SponsorProduct
              web3={web3}
              accounts={accounts}
              contracts={contracts}
              admin={admin}
                />
          </Route>
          <Route path="/manageTokens" exact>
              <CreateTokens
              web3={web3}
              accounts={accounts}
              contracts={contracts}
              admin={admin}
                />
          </Route>
          <Route path="/marketFunctions" exact>
              <MarkplaceFunction
              web3={web3}
              accounts={accounts}
              contracts={contracts}
              admin={admin}
                />
          </Route>
          {/* <Route path="/manageTokens" exact>
                  <ManageTokens
                  web3={web3}
                  accounts={accounts}
                  contracts={contracts}
                  admin={admin}
                   />
          </Route> */}

        {/* <Route path="/stakeFantasy" exact>
              <Fantasy
              web3={web3}
              accounts={accounts}
              contracts={contracts}
              admin={admin}
                />
          </Route> */}
        <Route path="/nfItems" exact>
              <NFTItems
              web3={web3}
              accounts={accounts}
              contracts={contracts}
              admin={admin}
                />
          </Route>
          <Route path="/MyNFTs" exact>
              <MyNFTs
              web3={web3}
              accounts={accounts}
              contracts={contracts}
              admin={admin}
                />
          </Route>
      </div>
    );
  
  
  return (
          <Router>
            <MainNavigation />
            <main>
              <Suspense 
                fallback={
                  <div className="center"> 
                    <LoadingSpinner/>
                  </div>
                }
              >
                <div className="container">
                {/* <DrizzleProvider drizzle={drizzle}>           
                  <LoadingContainer> */}
                    {routes}
                  {/* </LoadingContainer>                 
                </DrizzleProvider> */}
                </div>
              </Suspense>
            </main>
          </Router>
  );
};

export default App;
