import React, {useEffect, useState} from "react";
import logo from '../images/logo.png';
import '../imageStyle/logo.css'
import '../imageStyle/logo4.css'
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import Card21 from '../shared/components/UIElements/Card21';
import Card22 from '../shared/components/UIElements/Card22';
import Card23 from '../shared/components/UIElements/Card23';
import Card27 from '../shared/components/UIElements/Card27';
import Card18 from '../shared/components/UIElements/Card18';
import Card19 from '../shared/components/UIElements/Card19';
// import Price from './Price';
import '../imageStyle/logo2.css'
// import drizzleOptions from "./drizzleOptions";
import Card4 from '../shared/components/UIElements/Card4';
import Card20 from '../shared/components/UIElements/Card20';
import Card5 from '../shared/components/UIElements/Card5';
import Card6 from '../shared/components/UIElements/Card6';
import Card11 from '../shared/components/UIElements/Card11';
import '../shared/components/FormElements/Button.css';
import './SponsorItem.css';
import '../places/components/ProfileItem.css';
import {formatPoints, formatBalanceEth, formatBalance } from './DEX/helpers'




// TODO
// Side Note: Solidity returns objects so returning objects with react
// Make mintNFT() function
// make changeSeason() setRoyalties() toggleTransferPause() transferRoyalties()


const MyNFTs = ({ web3, accounts, contracts }) => {
  const [ownedNftIds, setOwnedNftIds] = useState([]);
  const [uriNft, setUriNft] = useState({});
  const [metadata, setMetadata] = useState({});
  const [nftData, setNftData] = useState({});
  const [nftBalances, setNftBalances] = useState({});

  useEffect(() => {
    async function fetchSupply() {
      try {
        const counts = {};

        for (const nftId of ownedNftIds) {
          const countsObject = await contracts.sportsClub.methods.totalSupplyTokenCap(nftId).call();
          counts[nftId] = {
            totalSupplyValue: countsObject[0],
            tokenCapValue: countsObject[1],
          };
        }

        setNftData(counts);
      } catch (error) {
        console.error('Error connecting to MetaMask:', error);
      }
    }
    fetchSupply();
  }, [ownedNftIds]);

  useEffect(() => {
    async function fetchMetadata() {
      try {
        const metadataPromises = ownedNftIds.map(async (nftId) => {
          const uri = await contracts.sportsClub.methods.uri(nftId).call();
          const response = await fetch(uri);
          if (!response.ok) {
            throw new Error(`Failed to fetch metadata for NFT ID ${nftId}`);
          }
          const data = await response.json();
          return { [nftId]: data };
        });

        const metadataResults = await Promise.all(metadataPromises);
        const metadataObject = Object.assign({}, ...metadataResults);
        setMetadata(metadataObject);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    }
    fetchMetadata();
  }, [uriNft, ownedNftIds]);

  useEffect(() => {
    async function fetchData() {
      try {
        const nftIdsArray = await contracts.sportsClub.methods.getNftIds().call();

        // Filter the NFT IDs to include only the ones owned by the current account
        const ownedIds = [];

        for (const nftId of nftIdsArray) {
          const balance = await contracts.sportsClub.methods.balanceOf(accounts[0], nftId).call();
          if (balance > 0) {
            ownedIds.push(nftId);
          }
          setNftBalances((prevState) => ({
            ...prevState,
            [nftId]: balance, // Store the balance in nftBalances state
          }));
        }

        setOwnedNftIds(ownedIds);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchData();
  }, [contracts.sportsClub, accounts]);

  return (
    <div>
      <div className="sponsor-item">
        <Card21 className="sponsor-item__content">
          <Card22>
            <div className="logo4-wrapper">
            <Card23 className="card-info19">
            <Card27 className="card-info27">
                <h2>My NFTs</h2>
                <p>Collect and upgrade your NFTs!</p>
                </Card27>
                </Card23>
              {/* <Card23 className="card-info19">
                <h2>Active Account: {accounts[0]}</h2>
              </Card23> */}
              <Card20 className="card-info20">
                <div>
                  <div className="sponsor-item">
                  {/* <Card23 className="card-info19">

                    <h2>Player NFTs</h2>
                    </Card23> */}
                    <div className="card-info20">
                      {ownedNftIds.map((nftId) => (
                        <Card11 className="card11" key={nftId}>
                          {metadata[nftId] ? (
                            <div>
                                <p>NFT ID: {nftId}</p>
                              <p>{metadata[nftId].name}</p>
                              <img src={metadata[nftId].image} alt="NFT" />
                                <p>My Balance: {nftBalances[nftId]}</p> {/* Display NFT balance */}
                              <h6>Total Supply: {nftData[nftId]?.totalSupplyValue}</h6>
                              <p>Rarity: {metadata[nftId].rarity}</p>
                              <p>Description: {metadata[nftId].description}</p>
                            </div>
                          ) : (
                            <p>Loading metadata...</p>
                          )}
                        </Card11>
                      ))}
                    </div>
                  </div>
                </div>
              </Card20>
            </div>
          </Card22>
        </Card21>
      </div>
    </div>
  );
};

export default MyNFTs;
