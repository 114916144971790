import web3 from 'web3';

export const ETHER_ADDRESS = '0x0000000000000000000000000000000000000000'


export const DECIMALS = (10**9)
export const DECIMALS2 = (10**3)
export const DECIMALS4 = (10**9)
export const DECIMALS3 = (10**18)
// Shortcut to avoid passing around web3 connection
// export const ether = (wei) => {
//   if(wei) {
//     return(wei * DECIMALS) // 18 decimal places
//   }
// }
function formatNumberWithCommas(number) {
  return new Intl.NumberFormat().format(number);
}

export const formatBalance = (balance) => {
  const precision = 100 // 2 decimal places

  balance = tokens(balance)
  balance = (Math.round(balance * precision) / precision).toFixed(2) // Use 2 decimal places

  return balance
}
export const formatPoints = (balance) => {
  const precision = 100 // 2 decimal places

  balance = points(balance)
  balance = (Math.round(balance * precision) / precision).toFixed(3) // Use 3 decimal places

  return formatNumberWithCommas(balance);
}
export const formatBalanceEth = (balance) => {
  const precision = 100; // 2 decimal places

  balance = ethPrice(balance);
  balance = (Math.round(balance * precision) / precision).toFixed(2); // Use 2 decimal places

  return formatNumberWithCommas(balance); // Format the balance with commas
}

export const ether = (n) => {
    return new web3.utils.BN(
      web3.utils.toWei(n.toString(), 'ether')
    )
  }
export const tokens = (wei) => {
    if(wei) {
        return(wei / DECIMALS) // 9 decimal places
    }
    }
export const tokenPrice = (wei) => {
    if(wei) {
        return(wei / DECIMALS2) // 3 decimal places
    }
    }

export const points = (wei) => {
    if(wei) {
        return(wei / DECIMALS4) // 4 decimal places
    }
    }
export const ethPrice = (wei) => {
    if(wei) {
      return(wei / DECIMALS3) // 18 decimal places
    }
  }

// Tokens and ether havesame decimal resolution
// export const tokens = ether

