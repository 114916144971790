import React, {useEffect, useState} from "react";
import '../imageStyle/logo.css'
import '../imageStyle/logo4.css'
import Card21 from '../shared/components/UIElements/Card21';
import Card22 from '../shared/components/UIElements/Card22';
import Card23 from '../shared/components/UIElements/Card23';
import Card20 from '../shared/components/UIElements/Card20';
import Card5 from '../shared/components/UIElements/Card5';
import Card6 from '../shared/components/UIElements/Card6';
import Card11 from '../shared/components/UIElements/Card11';
import '../shared/components/FormElements/Button.css';
import './SponsorItem.css';
import '../places/components/ProfileItem.css';
import {formatPoints, formatBalanceEth, formatBalance } from './DEX/helpers'

const CreateTokens = ({web3, accounts, contracts
    // , admin
  }) => {
        
  async function changeSeason(e) {
    e.preventDefault();
    const newSeason = parseInt(e.target.elements[0].value);
    const isOffSeason = e.target.elements[1].checked;
    if (isNaN(newSeason) ) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .changeSeason(newSeason, isOffSeason)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('75', 'gwei'), // Specify the gas price in gwei
          gas: 650000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
            });
          console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function setAccessRole(e) {
    e.preventDefault();
    const address = e.target.elements[0].value;    
    const id = parseInt(e.target.elements[1].value);
    const hasAccess = e.target.elements[2].checked;
    const addressAdmin = e.target.elements[3].value;    
    if (isNaN(id) ) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .setAccessRole(address, id, hasAccess, addressAdmin)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('75', 'gwei'), // Specify the gas price in gwei
          gas: 650000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
            });
          console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }
  // address _clubRoyaltyAddr,
  // address _erc20,
  // uint256 _minErc20,
  // uint256 minErc20Royalty,
  // uint256 _clubRoyalty, // Set at .5%? .75?
  // uint256 _multW,
  // uint256 newSeason,
  // uint256 newPointsId,
  // uint256 maxPoints,
  // uint256 newNumFactor,
  // uint256 numFactorId

  async function updateAddrsFees(e) {
    e.preventDefault();
    const feeAddress = e.target.elements[0].value;    
    const erc20Address = e.target.elements[1].value;  
    // const amountInput = e.target.elements[2].value;
    // const minErc20 = web3.utils.toWei(amountInput, 'ether'); // Convert to Wei
    const minErc20 = web3.utils.toWei((parseInt(e.target.elements[2].value)).toString(), 'ether');  
    const minErc20Royalty = web3.utils.toWei((parseInt(e.target.elements[3].value)).toString(), 'ether');  
    const clubRoyalty = parseInt(e.target.elements[4].value);    
    const multW = parseInt(e.target.elements[5].value);    
    const newSeason = parseInt(e.target.elements[6].value);   
    const newPointsId = parseInt(e.target.elements[7].value);   
    // const blockEndSeason = parseInt(e.target.elements[8].value);   
    // const totalUnitLimit = web3.utils.toWei((parseInt(e.target.elements[8].value)).toString(), 'gwei');   
    const maxPoints = web3.utils.toWei((parseInt(e.target.elements[8].value)).toString(), 'gwei');   
    const newNumFactor = parseInt(e.target.elements[9].value);   
    const numFactorId = parseInt(e.target.elements[10].value);   
    // const isOffSeason = e.target.elements[7].checked;
    // const isHaltPoints = e.target.elements[8].checked;
  
    // const rewardRate = parseInt(e.target.elements[5].value);    

    if (isNaN(minErc20) || isNaN(multW) || isNaN(newSeason) || isNaN(newPointsId)) {
      console.error('Invalid input. Please enter a valid integer.');
      return;
    }
    try {
      await contracts.sportsClub.methods
        .updateAddrsFees(feeAddress, erc20Address, minErc20, minErc20Royalty, clubRoyalty, multW, newSeason, newPointsId, maxPoints, newNumFactor, numFactorId)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('90', 'gwei'), // Specify the gas price in gwei
          gas: 900000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
            });
          console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
}

  async function editWinLossCount(e) {
    e.preventDefault();
    const id = parseInt(e.target.elements[0].value);    
    const wins = parseInt(e.target.elements[1].value);    
    const losses = parseInt(e.target.elements[2].value);  
    const account = accounts[0];  
    if (isNaN(id) || isNaN(wins) || isNaN(losses)) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .editWinLossCount(account, id, wins, losses)
        .send({from: account,
      gasPrice: web3.utils.toWei('75', 'gwei'), // Specify the gas price in gwei
      gas: 650000, // Specify the gas limit (Y is the gas limit value)
                // , gas: 600000
        });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

    async function setToken(e) {
    e.preventDefault();
    const id = parseInt(e.target.elements[0].value);    
    const cap = web3.utils.toWei((parseInt(e.target.elements[1].value)).toString(), 'gwei');  
    const royaltyAddress = e.target.elements[2].value;    
    const hasRoyalty = e.target.elements[3].checked;
    if (isNaN(id) || isNaN(cap)) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
    console.log(id, cap);
    try {
      await contracts.sportsClub.methods
        .setToken(id, cap, royaltyAddress, hasRoyalty)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('70', 'gwei'), // Specify the gas price in gwei
          gas: 600000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
            });
          console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // async function setRoyalty(e) {
  //   e.preventDefault();
  //   const id = parseInt(e.target.elements[0].value);    
  //   const account = e.target.elements[1].value;    
  //   if (isNaN(id)) {
  //     console.error('Invalid input. Please enter a valid integer for ID.');
  //     return;
  //   }
  //   console.log(id, account);
  //   try {
  //     await contracts.sportsClub.methods
  //       .setRoyalty(id, account)
  //       .send({from: accounts[0]
  //         , gas: 600000
  //       });
  //     console.log('Transaction successful.');
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // }

  async function mint(e) {
    e.preventDefault();
    const account = e.target.elements[0].value;
    const id = parseInt(e.target.elements[1].value);    
    // const amount = web3.utils.toBN((parseInt(e.target.elements[2].value)).toString());
    const amount = web3.utils.toWei((parseInt(e.target.elements[2].value)).toString(), 'gwei');
    // web3.utils.padRight(web3.utils.asciiToHex(e.target.elements[4].value), 64);
    // const name = e.target.elements[3].value;
    const name = web3.utils.padRight(web3.utils.asciiToHex(e.target.elements[3].value), 64);
    // const city = e.target.elements[4].value;
    const city = web3.utils.padRight(web3.utils.asciiToHex(e.target.elements[4].value), 64);
    // const division = e.target.elements[5].value;
    const division = web3.utils.padRight(web3.utils.asciiToHex(e.target.elements[5].value), 64);
    const uri = e.target.elements[6].value;
    const royaltyAddr = e.target.elements[7].value;
    const decimals = parseInt(e.target.elements[8].value);  
    const hasRoyalty = e.target.elements[9].checked;  
  
    const data = e.target.elements[10].value;

console.log(account, id, amount, name, city, uri, royaltyAddr, decimals, hasRoyalty, data);
    // Check if 'id' is a valid integer
    if (isNaN(id) || isNaN(decimals) | isNaN(amount)) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .mint(account, id, amount, name, city, division, uri, royaltyAddr, decimals, hasRoyalty, data)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('100', 'gwei'), // Specify the gas price in gwei
          gas: 1000000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
                  });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function mintNFT(e) {
    e.preventDefault();
    const account = e.target.elements[0].value;
    const amount = parseInt(e.target.elements[1].value);
    const uri = e.target.elements[2].value;
console.log(account, amount, uri);
    // Check if 'id' is a valid integer
    if (isNaN(amount)) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .mintNFT(account, amount, uri)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('75', 'gwei'), // Specify the gas price in gwei
          gas: 650000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
                  });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function updateTeamInfo(e) {
    e.preventDefault();
    const id = parseInt(e.target.elements[0].value);    
    const name = web3.utils.padRight(web3.utils.asciiToHex(e.target.elements[1].value), 64);
    const city = web3.utils.padRight(web3.utils.asciiToHex(e.target.elements[2].value), 64);
    const division = web3.utils.padRight(web3.utils.asciiToHex(e.target.elements[3].value), 64);
    const uri = e.target.elements[4].value;
  
    // Check if 'id' is a valid integer
    if (isNaN(id)) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .updateTeamInfo(id, name, city, division, uri)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('75', 'gwei'), // Specify the gas price in gwei
          gas: 650000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
                  });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function safeTransferFrom(e) {
    e.preventDefault();
    const address = e.target.elements[0].value;
    const id = parseInt(e.target.elements[1].value);
    const amountInput = e.target.elements[2].value;
    const amount = web3.utils.toWei(amountInput, 'gwei'); // Convert to Wei
    const data = e.target.elements[3].value;
  
    // Validate id and amount
    if (isNaN(id) || isNaN(parseFloat(amountInput))) {
      console.error('Invalid input. Please enter valid numbers for ID and amount.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .safeTransferFrom(accounts[0], address, id, amount, data)
        .send({
          from: accounts[0],
          gasPrice: web3.utils.toWei('100', 'gwei'), // Specify the gas price in gwei
          gas: 800000, // Specify the gas limit (Y is the gas limit value)
        });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  
  async function safeBatchTransferFrom(e) {
    e.preventDefault();
    const address = e.target.elements[0].value;
    const ids = e.target.elements[1].value.split(',').map(Number); // Parse input as integers

    // Convert amounts to Wei individually for each number
    const amountsInput = e.target.elements[2].value.split(',');
    const amounts = amountsInput.map(amount => web3.utils.toWei(String(amount), 'gwei'));

    const data = e.target.elements[3].value;
    console.log("ids:", ids);
    console.log("amounts:", amounts);
    
    // Validate id and amount
    if (!ids.every(Number.isInteger) || !amounts.every(value => typeof value === "string")) {
      console.error('Invalid input. Please enter valid numbers for ID and amount.');
      return;
  }
    
    try {
      await contracts.sportsClub.methods
        .safeBatchTransferFrom(accounts[0], address, ids, amounts, data)
        .send({
          from: accounts[0],
          gasPrice: web3.utils.toWei('100', 'gwei'), // Specify the gas price in gwei
          gas: 1000000, // Specify the gas limit (Y is the gas limit value)
        });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
}

  async function updateRewards(e) {
    e.preventDefault();
    await contracts.sportsClub.methods
      .updateReward()
      .send({from: accounts[0]});
  }
  // id name city uri
  // need to set royalty before calling fucntion
  // async function updateTeamInfo(e) {
  //   e.preventDefault();
  //   const id = parseInt(e.target.elements[0].value);    
  //   const name = e.target.elements[1].value.split(',');//no spaces between address or will throw error
  //   const city = e.target.elements[2].value.split(',');//no spaces between address or will throw error
  //   const uri = e.target.elements[3].value.split(',');//no spaces between address or will throw error
  //   await contracts.sportsClub.methods
  //     .updateTeamInfo(id, name, city, uri)
  //     .send({from: accounts[0]});
  // }


  async function logWinLossCount(e) {
    e.preventDefault();
    const idW = e.target.elements[0].value.split(',').map(Number); // Parse input as integers
    const idL = e.target.elements[1].value.split(',').map(Number); // Parse input as integers
    const account = accounts[0];
    console.log(idW, idL);
    // Make sure idW and idL are valid integers before proceeding
    // if (isNaN(idW) || isNaN(idL)) {
    //   console.error('Invalid input. Please enter valid integer values.');
    //   return;
    // }
      // Make sure idW and idL are valid arrays of integers before proceeding
      if (!idW.every(Number.isInteger) || !idL.every(Number.isInteger)) {
        console.error('Invalid input. Please enter valid integer values.');
        return;
      }

    try {
      await contracts.sportsClub.methods
        .logWinLossCount(idW, idL)
        .send({from: account,
          gasPrice: web3.utils.toWei('75', 'gwei'), // Specify the gas price in gwei
          gas: 650000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
            });
      
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
    // setRewardsBalance(rewardsBalance);

  }

  async function removeItem(e) {
    e.preventDefault();
    const tokenId = parseInt(e.target.elements[0].value);
    try {
      const accounts = await web3.eth.getAccounts();

      const receipt = await contracts.sportsClub.methods.removeItem(tokenId)
        .send({ from: accounts[0] });

      console.log('Transaction receipt: ', receipt);
    } catch (error) {
      console.error('Error while removing item: ', error);
    }
  }

  async function toggleTransfersPaused(e
      // , isPaused
      ) {
    e.preventDefault();
    const isPaused = e.target.elements[0].checked;  
    console.log(isPaused);
    try {
      await contracts.sportsClub.methods
        .toggleTransfersPaused(isPaused)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('70', 'gwei'), // Specify the gas price in gwei
          gas: 600000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
                  });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div>
  <div className="sponsor-item">

  <Card21 className="sponsor-item__content">
  <Card23 className="card-info19">
  <h2>Choose Your Team</h2> 
  </Card23>
    <Card22 >
        <div className='logo4-wrapper' > 
      <Card23 className="card-info19">
          {/* <img src={logo} alt='logo4'/> */}
      <h2>Active Account: {accounts[0]}</h2>
      <h2>Your CFC Balance: 
        {/* {web3.utils.fromWei((userCfcBalance).toString(), 'ether')} */}
        </h2>
        <form onSubmit={e => (logWinLossCount(e))}>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Log Win</button>
        </form>
        <br></br>

        <form onSubmit={e => (safeTransferFrom(e))}>
        <div className="col-sm-8">
        <h3>Address</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Token ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Amount</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Data</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Transfer Token</button>
        </form>
        <br></br>

        <form onSubmit={e => (safeBatchTransferFrom(e))}>
        <div className="col-sm-8">
        <h3>Address</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Token IDs</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Amounts</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Data</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">BatchTransfer Token</button>
        </form>
        <br></br>


        <form onSubmit={e => (setToken(e))}>
        <div className="col-sm-8">
        <h3>ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Cap/Max Supply</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>Royalty Address</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Is Royalty?</h3>
        <input type="checkbox" className="form-control" id="hasRoyalty" />
        </div>
        <button type="submit" className="button">Set Token</button>
        </form>
        <br></br>

        {/* <form onSubmit={e => (setRoyalty(e))}>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Set Royalty</button>
        </form>
        <br></br> */}

        <form onSubmit={e => (toggleTransfersPaused(e))}>
        <div className="col-sm-8">
        <h3>Is Paused</h3>
        <input type="checkbox" className="form-control" id="isPaused" />
        </div>
        <button type="submit" className="button">Toggle Paused</button>
        
        </form>
        <br></br>

        <form onSubmit={e => (setAccessRole(e))}>
        <div className="col-sm-8">
        <h3>Address</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Role ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>Has Access</h3>
        <input type="checkbox" className="form-control" id="hasAccess" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Admin Address</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Set Access Role</button>
        </form>
        <br></br>

        <form onSubmit={e => (changeSeason(e))}>
        <div className="col-sm-8">
        <h3>Season</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>Is Off Season</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <button type="submit" className="button">Change Season</button>
        </form>
        <br></br>

        <form onSubmit={e => (editWinLossCount(e))}>
        <div className="col-sm-8">
        <h3>ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>New Wins</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>New Losses</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Edit Win Loss</button>
        </form>
        <br></br>

        <form onSubmit={e => (updateTeamInfo(e))}>
        <div className="col-sm-8">
        <h3>ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>Name</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>City</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>Division</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>URI</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Update Team</button>
        </form>

        <form onSubmit={e => (updateAddrsFees(e))}>
        <div className="col-sm-8">
        <h3>Fee Address</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>ERC20 Address</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>Minimum ERC20 Balance for Rewards</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>Minimum ERC20 Balance for Royalties</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>New Club Royalty</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>Winning Multiplier</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>New Season</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <br></br>
        <h3>New Points Balance ID</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>max Points</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3> New Numbert Factor</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>number Factor Id</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>

        {/* <br></br>
        <br></br>
        <h3>Is Off Season?</h3>
        <div className="col-sm-8">
        <input type="checkbox" className="form-control" id="isOffSeason" />
        </div> */}

        <br></br>
        {/* <h3>Block Reward Rate</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div> */}
        <button type="submit" className="button">Update Multipliers/Rules</button>
        </form>

        <form onSubmit={e => (removeItem(e))}>
        <div className="col-sm-8">
        <h3>ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <button type="submit" className="button">Remove ID</button>
        </form>
        <br></br>

        <form onSubmit={e => (mint(e))}>
        <div className="col-sm-8">
        <h3>Account</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>amount</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>Name</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>City</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>Division</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>URI</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <h3>Royalty Address</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>Decimals</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>Is Royalty?</h3>
        <input type="checkbox" className="form-control" id="hasRoyalty" />
        <h3>Data</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Mint Team</button>
        </form>

        <form onSubmit={e => (mintNFT(e))}>
        <div className="col-sm-8">
        <h3>Account</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>amount</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>URI</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">MintNFT</button>
        </form>
      </Card23>
      
      <Card23 className="card-info19">
          {/* <img src={logo} alt='logo4'/> */}
          <h2>Active Account: {accounts[0]}</h2>
      <h2>
        {/* Reward Points: {(formatPoints(rewardsBalance))} */}
        </h2>
      </Card23>

            {/* <Card20 className="card-info20">
                <Card11 className="card11">
                  <img src={Pirate} alt=""/>
                  <h2>{tokens[0].name}</h2>
                  <h6>TokenId {tokens[0].tokenId}</h6>
                  <h4>  My Balance: {formatBalanceEth(token1)}</h4>
                  <h4>Record</h4>
                    {record1 && (
                      <p>
                        Wins: {record1[0]}<br />
                        Losses: {record1[1]}
                      </p>
                    )}                        
                    <h6>City: {tokens[0].city}</h6>
                  <h6>Supportors {supportors1}</h6>
                  </Card11>
                  <Card11 className="card11">
                  <img src={Ninja} alt=""/>
                      <h2>{tokens[1].name}</h2>
                      <h6>TokenId {tokens[1].tokenId}</h6>
                      <h4>  My Balance: {formatBalanceEth(token2)}</h4>
                      <h4>Record</h4>
                    {record2 && (
                      <p>
                        Wins: {record2[0]}<br />
                        Losses: {record2[1]}
                      </p>
                    )}                        

                  <h6>City: {tokens[1].city}</h6>
                  <h6>Supportors {supportors2}</h6>
                  </Card11>

                  <Card11 className="card11">
                  <img src={jaguar} alt=""/>
                      <h2>{tokens[2].name}</h2>
                  <h6>TokenId {tokens[2].tokenId}</h6>
                      <h4>  My Balance: {formatBalanceEth(token3)}</h4>
                      <h4>Record</h4>
                    {record3 && (
                      <p>
                        Wins: {record3[0]}<br />
                        Losses: {record3[1]}
                      </p>
                    )}                        

                  <h6>City: {tokens[2].city}</h6>
                  <h6>Supportors {supportors3}</h6>
                  
                  
                  </Card11>

                  <Card11 className="card11">
                  <img src={Pirate} alt=""/>
                  <h2>{tokens[3].name}</h2>
                  <h6>TokenId {tokens[3].tokenId}</h6>
                  <h4>  My Balance: {formatBalanceEth(token4)}</h4>
                  <h4>Record</h4>
                    {record4 && (
                      <p>
                        Wins: {record4[0]}<br />
                        Losses: {record4[1]}
                      </p>
                    )}                        
                    <h6>City: {tokens[3].city}</h6>
                  <h6>Supportors {supportors4}</h6>
                  </Card11>

                  <Card11 className="card11">
                  <img src={Ninja} alt=""/>
                      <h2>{tokens[4].name}</h2>
                      <h6>TokenId {tokens[4].tokenId}</h6>
                      <h4>  My Balance: {formatBalanceEth(token5)}</h4>
                      <h4>Record</h4>
                    {record5 && (
                      <p>
                        Wins: {record5[0]}<br />
                        Losses: {record5[1]}
                      </p>
                    )}                        

                  <h6>City: {tokens[4].city}</h6>
                  <h6>Supportors {supportors5}</h6>
                  
                  </Card11>

                  <Card11 className="card11">
                  <img src={jaguar} alt=""/>
                      <h2>{tokens[2].name}</h2>
                  <h6>TokenId {tokens[2].tokenId}</h6>
                      <h4>  My Balance: {formatBalanceEth(token3)}</h4>
                      <h4>Record</h4>
                    {record3 && (
                      <p>
                        Wins: {record3[0]}<br />
                        Losses: {record3[1]}
                      </p>
                    )}                        

                  <h6>City: {tokens[2].city}</h6>
                  <h6>Supportors {supportors3}</h6>
                  
                  
                  </Card11>


              </Card20 >             */}
        </div>
      </Card22>
  </Card21>
  </div> 
</div>
);
};
export default CreateTokens;
