import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

// import { AuthContext } from '../../context/auth-context';
import './NavLinks.css';

const NavLinks = props => {
  // const auth = useContext(AuthContext);

  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/" exact>
          Home
        </NavLink>
      </li>
      {/* {auth.isLoggedIn && ( */}
        <li>
          <NavLink to={`/Teams`}>Team Tokens</NavLink>
        </li>
      {/* {auth.isLoggedIn && (  */}
      <li>
          <NavLink to={`/nfItems`}>NFTs</NavLink>
        </li> 
      {/* )} */}
      {/* {auth.isLoggedIn && (  */}
      <li>
          <NavLink to={`/myNFTs`}>My NFTs</NavLink>
        </li> 
      {/* )} */}
      <li>
          <NavLink to={`/manageTokens`}>Manage Tokens</NavLink>
        </li>
      {/* {auth.isLoggedIn && (  */}
      {/* )} */}
      <li>
          <NavLink to={`/marketfunctions`}>MarkePlace Functions</NavLink>
        </li>
      {/* {auth.isLoggedIn && (  */}
       {/* <li>
          <NavLink to={`/stakeFantasy`}>Stake Athlete</NavLink>
        </li> */}
        {/* )} */}

    </ul>
  );
};

export default NavLinks;
