import React, { useEffect, useState } from 'react';

// import UsersList from '../components/UsersList';
// import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
// import { useHttpClient } from '../../shared/hooks/http-hook';
import logo from '../../images/logo.png';
// import Sponsor from '../../images/Sponsor.png';
// import OfficialCard from '../../images/OfficialCard.png';
// import CFCbackground from '../../images/CFCbackground.png';
// import Background from '../../images/Background.mov';
import Background from '../../images/Background.png';


// import ChampionEditionCard from '../../images/ChampionEditionCard.png';
// import Roadmap from '../../images/Roadmap.png';
// import ProfileManagerHistory from '../../images/Profile-Manager-History.png';
// import Manager from '../../images/Manager.png';
// import Membership from '../../images/membership.png';
// import NetworkEffect from '../../images/Network-Effect.png';

import '../../imageStyle/logo3.css'
import Card4 from '../../shared/components/UIElements/Card4';
import Card11 from '../../shared/components/UIElements/Card11';
import Card12 from '../../shared/components/UIElements/Card12';
import Card15 from '../../shared/components/UIElements/Card15';
import Card16 from '../../shared/components/UIElements/Card16';
import Card18 from '../../shared/components/UIElements/Card18';
import Card19 from '../../shared/components/UIElements/Card19';
import Card17 from '../../shared/components/UIElements/Card17';
import Card27 from '../../shared/components/UIElements/Card27';
import Card28 from '../../shared/components/UIElements/Card28';
import Card29 from '../../shared/components/UIElements/Card29';
// import Card13 from '../../shared/components/UIElements/Card13';
// import Card14 from '../../shared/components/UIElements/Card14';
// import Card20 from '../../shared/components/UIElements/Card20';
import '../components/HomePage.css';
import { useSportsData } from '../../TokenProduct/SportsDataContext';


const Users = () => {
  // const { isLoading, error, sendRequest, clearError } = useHttpClient();
  // const [loadedUsers, setLoadedUsers] = useState();

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //       const responseData = await sendRequest(
  //         process.env.REACT_APP_BACKEND_URL + '/users'
  //       );

  //       setLoadedUsers(responseData.users);
  //     } catch (err) {}
  //   };
  //   fetchUsers();
  // }, [sendRequest]);
  const [tokenIds] = useState([1, 2, 3, 4, 5, 6]);
  const [southTokenIds] = useState([4, 5, 6]);  
  
  const { tokenHolderCounts, uriValues, winLossCounts, itemsObject, marketObject } = useSportsData();
  
  return (
    <React.Fragment>
      {/* <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )} */}
        <Card12 className="homepage__content">
        {/* <div className='logo3-wrapper' > 
        <img src={logo} alt='logo'/>
      </div> */}
        <div className='homepage__image2' > 
        <img src={logo} alt='logo'/>
        </div>

      {/* {!isLoading && loadedUsers && <UsersList items={loadedUsers} />} */}
      <div className='logo4-wrapper'>
      <Card27 className="">
        <h2>Beta Early testing Not yet public/Rebranding</h2>
      <Card29 className="card-info29">
      {tokenIds.map((tokenId) => (
        <Card11 className="card11 p " key={tokenId}>
          <img src={uriValues[tokenId]} alt="" />
          <Card28 className="">
          <p>Fans: {tokenHolderCounts[tokenId]}</p>
          {/* <p>Record: {winLossCounts[tokenId]?.winCount}-{winLossCounts[tokenId]?.lossCount}</p> */}
          </Card28>
        </Card11>
         ))}
        </Card29>
          {/* <div className="video-wrapper">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/G2nE6v0FarU" frameBorder="0" allowFullScreen></iframe>
          </div>         */}
        </Card27>
        </div>

        </Card12>
    </React.Fragment>
  );
};

export default Users;
