import React, {useEffect, useState} from "react";
import logo from '../images/logo.png';
import '../imageStyle/logo.css'
import '../imageStyle/logo4.css'
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import Card21 from '../shared/components/UIElements/Card21';
import Card22 from '../shared/components/UIElements/Card22';
import Card23 from '../shared/components/UIElements/Card23';
import Card26 from '../shared/components/UIElements/Card26';
import Card27 from '../shared/components/UIElements/Card27';
import Card28 from '../shared/components/UIElements/Card28';
import Card29 from '../shared/components/UIElements/Card29';
import Card30 from '../shared/components/UIElements/Card30';
import Card24 from '../shared/components/UIElements/Card24';
import Card16 from '../shared/components/UIElements/Card16';
import Card17 from '../shared/components/UIElements/Card17';
import Card18 from '../shared/components/UIElements/Card18';
import Card19 from '../shared/components/UIElements/Card19';
// import Price from './Price';
import '../imageStyle/logo2.css'
import Card4 from '../shared/components/UIElements/Card4';
import Card20 from '../shared/components/UIElements/Card20';
import Card5 from '../shared/components/UIElements/Card5';
import Card6 from '../shared/components/UIElements/Card6';
import Card7 from '../shared/components/UIElements/Card7';
import Card11 from '../shared/components/UIElements/Card11';
import '../shared/components/FormElements/Button.css';
import '../shared/components/FormElements/Button2.css';
import './SponsorItem.css';
import '../places/components/ProfileItem.css';
import {formatPoints, formatBalanceEth, formatBalance } from './DEX/helpers'




// TODO
// uri() is not getUri() needs to be changed in function
// web3.utils.fromAscii(token.ticker)
// Start to integrate Marketplace.sol
// Redo setAccessRole
// capture emitted events from Contract
//Side Note: when Playoff start season will be switched to offseason and curSeason will be 10,000?
// -------cont... and championships will be playing at 20,000 for reward purposes? All champ games will be recorded in struct
// Or all playoff/championship games are included/counted as overall wins for curSeason.
// Side Note: Solidity returns objects so returning objects with react
// Make 
// make changeSeason() setRoyalties() toggleTransferPause() transferRoyalties()

const SponsorProduct = ({web3, accounts, contracts, admin
}) => {
  


  const [rewardValue, setRewardValue] = useState(null);
  const [tokenBalances, setTokenBalances] = useState({});
  const [marketBalances, setMarketBalances] = useState({});
  const [itemsObject, setItemsObject] = useState({});
  const [marketObject, setMarketObject] = useState({});
  const [winLossCounts, setWinLossCounts] = useState({});
  const [uriValues, setUriValues] = useState({});
  const [tokenIds, setTokenIds] = useState([]);
  const [tokenHolderCounts, setTokenHolderCounts] = useState({});

  // Separate token IDs into "Blue" and "Red" categories
  // const northTokenIds = tokenIds.filter((tokenId) => tokenId < 4);
  // const southTokenIds = tokenIds.filter((tokenId) => tokenId >= 4);
  const [northTokenIds] = useState([1, 2, 3]);
  const [southTokenIds] = useState([4, 5, 6]);  
  // const [eastTokenIds] = useState([17, 18, 19, 20, 21, 22, 23, 24]);
  // const [westTokenIds] = useState([25, 26, 27, 28, 29, 30, 31, 32]);  
  const [info, setInfo] = useState({
    curSeason: 0,
    nftCount: 0,
    royaltyFee: 0,
    multW: 0,
    minErc20: 0,
    minErc20Royalty: 0,
    pointsId: 0,
    blockEndSeason: 0,
    pointsDecimals: 0,
    // offSeason: true,
    // haltPoints: true,
  });

  const [balance, setBalance] = useState(0);
  const [pointsBal, setPointsBal] = useState(0);
  const [teamPointsBal, setTeamPointsBal] = useState(0);
  const [teamPointsBalanceMap, setTeamPointsBalanceMap] = useState({});


  const getBalance = async (accountAddress) => {
    try {
      const fetchedBalance = await contracts.sport.methods.balanceOf(accountAddress).call();
      setBalance(fetchedBalance);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };


  const getTeamPointsBalances = async (accountAddress, ids) => {
    try {
      // Map over the ids and return a list of promises for the balances
      const balancePromises = ids.map(id =>
        contracts.sportsClub.methods.getPointsBalances(accountAddress, id).call()
      );
  
      // Resolve all promises concurrently
      const balances = await Promise.all(balancePromises);
  
      // Create an object to map each ID to its balance
      const teamPointsBalanceMap = balances.reduce((acc, balance, index) => {
        // The second element in the balance array should be the teamPointsBalance for the corresponding ID
        const teamPointsBalance = balance[1];
        const id = ids[index];
        acc[id] = teamPointsBalance;
        return acc;
      }, {});
  
      // Set state with the object mapping ID to balance
      setTeamPointsBalanceMap(teamPointsBalanceMap);
      console.log(teamPointsBalanceMap);
  
    } catch (error) {
      console.error("Error fetching team points balances:", error);
    }
  };


  useEffect(() => {
    // Array of IDs you want to fetch balances for
    const ids = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 19, 20];
    // The user's account address

    // Fetch and set team points balances in the component's state
    getTeamPointsBalances(accounts[0], ids);
  }, []);
    

  const getPointsBal = async (accountAddress, id) => {
    try {
      const fetchedPointsData = await contracts.sportsClub.methods.getPointsBalances(accountAddress, id).call();
      // fetchedPointsData should be an array [pointsBalance, teamPointsBalance]
      const pointsBalance = fetchedPointsData[0];
      const teamPointsBalance = fetchedPointsData[1];
      
      // Assuming setPointsBal and setTeamPointsBal are state setter functions created via useState
      setPointsBal(pointsBalance);
      setTeamPointsBal(teamPointsBalance);
  
      // // Or if you want to handle both balances together as an object
      // setPointsData({
      //   pointsBalance: pointsBalance,
      //   teamPointsBalance: teamPointsBalance
      // });
  
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };
  
  async function getInfoFromContract(e) {
    e.preventDefault();
    const id = parseInt(e.target.elements[0].value);
    
    try {
      // Call the getInfo function from your contract
      const result = await contracts.sportsClub.methods.getInfo(id).call();

      // Update the info state with the retrieved data
      setInfo({
        numFactor: result[0],
        curSeason: result[1],
        nftCount: result[2],
        multW: result[3],
        minErc20: result[4],
        minErc20Royalty: result[5],
        pointsId: result[6],
        maxPoints: result[7],
        pointsDecimals: result[8]
        // offSeason: result[7],
        // haltPoints: result[8],
            });
            console.log(info.pointsDecimals)
          } catch (error) {
      console.error('Error fetching info:', error);
    }
  };

  async function callUpdatePoints(e) {
    e.preventDefault();
    const id = parseInt(e.target.elements[0].value);
    try {
      const result = await contracts.sportsClub.methods.updatePoints(accounts[0], id).call({
        from: accounts[0],
      });
      setRewardValue(result);
      console.log('Update Reward Result:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  async function updatePoints(e, account, id) {
    e.preventDefault();
    // const id = parseInt(e.target.elements[0].value);
  
    // Validate id and amount
    if (isNaN(id)) {
      console.error('Invalid input. Please enter valid numbers for ID and amount.');
      return;
    }

    try {
      const result = await contracts.sportsClub.methods.updatePoints(account, id).call({
        from: accounts[0],
      });

      await contracts.sportsClub.methods
      .updatePoints(account, id)
      .send({from: accounts[0],
        gasPrice: web3.utils.toWei('70', 'gwei'), // Specify the gas price in gwei
        gas: 600000, // Specify the gas limit (Y is the gas limit value)
        // , gas: 600000
      });
      console.log('Transaction successful.');
      
      setRewardValue(result);
      console.log('Update Reward Result:', result);
} catch (error) {
      console.error('Error:', error);
    }
  }

  async function buyTeamToken(e, id) {
    e.preventDefault();

    // Grab the amount from the form
    const amount = e.target.elements[0].value;
    const numAmount = Number(amount);

    // Ensure amount is a valid number
    if (isNaN(numAmount)) {
        console.error("Invalid amount provided:", amount);
        alert('Invalid amount. Please check the console.');
        return;
    }

    // Fetch the token data
    const tokenData = await contracts.marketplace.methods.tokens(id).call();
    if (!tokenData || !tokenData.price) {
        console.error("Unable to fetch token data or token price for ID:", id);
        alert('Error fetching token data. Please try again.');
        return;
    }
    console.log("Token price in Eth/Matic/BNB", tokenData.price);
    const numTokenPrice = Number(tokenData.price);
    const valueSent = numAmount * numTokenPrice;
    
    // Convert the amount and valueSent to Wei
    const amountInEth = web3.utils.toWei(amount.toString(), 'ether');
    const valueSentInEth = web3.utils.toWei(valueSent.toString(), 'wei');
    
    console.log("quantity amount in ethers", amountInEth);
    // Ensure valid conversion to Wei
    if (isNaN(Number(amountInEth)) || isNaN(Number(valueSentInEth))) {
        console.error('Invalid conversion to Wei. Check your calculations.');
        alert('Error in calculation. Please check the console.');
        return;
    }

    try {
        await contracts.marketplace.methods.buyTokens(id, amountInEth)
        .send({from: accounts[0],
          value: valueSentInEth,
          gasPrice: web3.utils.toWei('70', 'gwei'), // Specify the gas price in gwei
          gas: 600000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
            });

        alert('Token purchased successfully!');
    } catch (error) {
        console.error("Error buying token:", error);
        alert('Error buying token. Check transaction on scanner');
    }
}


//   async function buyTeamToken(e, tokenId) {
//     e.preventDefault();

//     const amountStr = e.target.elements[0].value; // Taking the input value directly.
    
//     // Validate the input values
//     if (!tokenId || !amountStr) {
//         alert('Please ensure all form fields have values.');
//         return;
//     }

//     const amount = parseFloat(amountStr); // Convert string to float
//     if (isNaN(amount)) {
//         alert('Invalid amount. Please enter a valid number.');
//         return;
//     }

//     try {
//         // Fetching the token price from the tokens mapping
//         const tokenData = await contracts.marketplace.methods.tokens(tokenId).call();
//         const tokenPrice = tokenData.price;

//         if (!tokenPrice) {
//             alert('Error fetching token price. Please check the console.');
//             return;
//         }

//         const valueSent = amount * tokenPrice;

//         // Convert the amount and valueSent to Wei
//         const amountInEth = web3.utils.toWei(amount.toString(), 'ether');
//         const valueSentInEth = web3.utils.toWei(valueSent.toString(), 'ether');

//         await contracts.marketplace.methods.buyTokens(tokenId, amountInEth)
//             .send({
//                 from: accounts[0],
//                 value: valueSentInEth
//             });

//         alert('Token purchased successfully!');
//     } catch (error) {
//         console.error("Error buying token:", error);
//         alert('Error buying token. Please check the console.');
//     }
// }


  useEffect(() => {
    async function fetchTokenIds() {
      try {
        // Call the getNftIds function
        const tokenIdsArray = await contracts.sportsClub.methods.getItemIds().call();

        // Update the state with the NFT IDs
        setTokenIds(tokenIdsArray);
        console.log(pointsBal);
        console.log(balance);
        console.log(tokenIds);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    
    getBalance(accounts[0]);
    getPointsBal(accounts[0], 1);
    fetchTokenIds();
  }, []);




  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch token holder counts, URIs, win/loss counts, and items concurrently
        const [tokenHolderCounts, uriResults, winLossCounts, fetchedItemsArray, fetchedMarketArray] = await Promise.all([
          contracts
            ? Promise.all(
                tokenIds.map(async (tokenId) => {
                  const count = await contracts.sportsClub.methods.getTokenHolderCount(tokenId).call();
                  return { [tokenId]: count };
                })
              )
            : [],
          Promise.all(
            tokenIds.map(async (tokenId) => {
              const uri = await contracts.sportsClub.methods.uri(tokenId).call();
              return { [tokenId]: uri };
            })
          ),
          Promise.all(
            tokenIds.map(async (tokenId) => {
              const countsObject = await contracts.sportsClub.methods.getWinLossCount(1, tokenId).call();
              return { [tokenId]: { winCount: countsObject[0], lossCount: countsObject[1] } };
            })
          ),
          contracts
            ? contracts.sportsClub.methods.getItems().call()
            : [],
          contracts
            ? contracts.marketplace.methods.getTokens().call()
            : [],
        ]);

        // Update token holder counts
        const tokenHolderCountsObject = Object.assign({}, ...tokenHolderCounts);
        setTokenHolderCounts(tokenHolderCountsObject);

        // Update URIs
        const uriObject = Object.assign({}, ...uriResults);
        console.log(uriResults);
        setUriValues(uriObject);

        // Update win/loss counts
        const winLossCountsObject = Object.assign({}, ...winLossCounts);
        setWinLossCounts(winLossCountsObject);

        // Update items
        const itemsObject = {};
        fetchedItemsArray.forEach((item) => {
          itemsObject[item.tokenId] = {
            tokenId: item.tokenId,
            name: item.name,
            city: item.city,
            division: item.division,
            decimalPlaces: item.decimalPlaces,
          };
        });
        setItemsObject(itemsObject);
        console.log(itemsObject);

        // Update items
        const marketObject = {};
        fetchedMarketArray.forEach((tokens) => {
          marketObject[tokens.tokenId] = {
            tokenId: tokens.tokenId,
            name: tokens.name,
            decimalPlaces: tokens.decimalPlaces,
            price: tokens.price,
            pointsCost: tokens.pointsCost,
          };
        });
        setMarketObject(marketObject);
        console.log(marketObject);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchData();
  }, [contracts, tokenIds]);

  useEffect(() => {
    let isMounted = true;

    const fetchTokenBalances = async () => {
      try {
        const updatedTokenBalances = {};

        for (let i = 0; i < tokenIds.length; i++) {
          const tokenId = tokenIds[i];
          const balancesOf = await contracts.sportsClub.methods.balanceOf(accounts[0], tokenId).call();
          updatedTokenBalances[tokenId] = balancesOf;
        }

        if (isMounted) {
          setTokenBalances(updatedTokenBalances);
        }
      } catch (error) {
        console.error('Error fetching token balances:', error);
      }
    };

    fetchTokenBalances();

    return () => {
      isMounted = false;
    };
  }, [accounts, tokenIds]);


  useEffect(() => {
    let isMounted = true;
    const fetchMarketBalances = async () => {
      try {
        const updatedMarketBalances = {};
        const marketAddress = await contracts.marketplace.options.address;

        for (let i = 0; i < tokenIds.length; i++) {
          const tokenId = tokenIds[i];
          const balancesOf = await contracts.sportsClub.methods.balanceOf(marketAddress, tokenId).call();
          updatedMarketBalances[tokenId] = balancesOf;
        }
        console.log(marketAddress);

        if (isMounted) {
          setMarketBalances(updatedMarketBalances);
        }
      } catch (error) {
        console.error('Error fetching token balances:', error);
      }
    };

    fetchMarketBalances();

    return () => {
      isMounted = false;
    };
  }, [tokenIds]);

  return (
    <div>
      <div className="sponsor-item">
        <Card21 className="sponsor-item__content">
          <Card23 className="card-info19">
            <Card27 className="card-info27">
              <div>
                <h2>League Point Balance</h2>
                {/* <h3>{web3.utils.fromWei((pointsBal).toString(), 'gwei')}</h3> */}
                <h3>{formatPoints(pointsBal).toString()}</h3>
                <Card27 className="card-infro6">
                {/* <h2>Unclaimed Points</h2> */}

                {/* <h3> {rewardValue !== null ? web3.utils.fromWei((rewardValue).toString(), 'gwei') - web3.utils.fromWei((pointsBal).toString(), 'gwei') : 'Click Button'}</h3> */}
                {/* <h3> {rewardValue !== null ? formatPoints(rewardValue - pointsBal) : 'Click Button'}</h3> */}
                {/* <button onClick={callUpdatePoints} className="button">Check unclaimed Points</button> */}

                {/* <form onSubmit={(e) => callUpdatePoints(e)}>
                <div className="card11">
                <input type="text" className="form-control" id="amount" />
                </div>
                    <button type="submit" className="button">Check unclaimed Points</button>                    
                  </form>    */}
                  <p>* Note: Claim points at least once a week for each team!! Unclaimed rewards can be lost. Active fan will be rewarded more.</p>
                </Card27>
                <Card28 className="card-info28">
                <h2> </h2>
                </Card28>
                {/* <Card27 className="card-infro6">
                <form onSubmit={(e) => updatePoints(e, accounts[0])}>
                <div className="card11">
                <input type="text" className="form-control" id="amount" />
                </div>
                    <button type="submit" className="button">Update Points</button>                    
                  </form>                  
                <p>* Note: Claim points at least once a week!! Unclaimed rewards can be lost. Active fan will be rewarded more.</p>

                </Card27> */}
              </div>
              {/* <div>
                <h2>Reward Points</h2>
                <h3> {rewardValue !== null ? web3.utils.fromWei((rewardValue).toString(), 'gwei') : 'Click Button'}</h3>
                <button onClick={callUpdatePoints} className="button">Update My Rewards</button>
              </div> */}

              <br></br>
              {/* <h2>Your SPORT Balance: { web3.utils.fromWei((balance).toString(), 'ether')}</h2> */}
              <h2>Your SPORT Balance: { formatBalanceEth(balance)}</h2>
              {/* <p>Balance: {balance}</p> */}
              <p>* Note: To earn points you need 1,000 SPORT tokens in your wallet</p>
    {accounts[0].toLowerCase() === admin.toLowerCase() ? (
                 <>

    <div>
      <form onSubmit={(e) => getInfoFromContract(e)}>
      <div className="card11">
      {/* <h3>Token ID</h3> */}
      <input type="text" className="form-control" id="amount" />
      </div>
      <button type="submit" className="button">Get Info</button>                    
                  </form>                  
      
      {/* <button onClick={getInfoFromContract}>Get Info</button> */}
      <div>
        <h2>Info:</h2>
        <p>Number Factor: {info.numFactor}</p>
        <p>Current Season: {info.curSeason}</p>
        <p>NFT Count: {info.nftCount - 5000000}</p>
        <p>MultW: {info.multW}</p>
        <p>MinErc20: {info.minErc20}</p>
        <p>minErc20Royalty: {info.minErc20Royalty}</p>
        <p>Points balance ID: {info.pointsId}</p>
        <p>Max Points: {info.maxPoints}</p>
        <p>Points Decimals: {info.pointsDecimals}</p>
        {/* <p>Off Season? : {info.offSeason === true ? 'Yes' : 'No'}</p>
        <p>Point Halted? : {info.haltPoints === true ? 'Yes' : 'No'}</p>
        <p>haltPoints: {(info.haltPoints).toString()}</p> */}
      </div>
    </div>
    </>
    ) : null}

            </Card27>
          </Card23>
          <Card22>
            <div className='logo4-wrapper'>
              <div>
                <Card27 className="card-info19">
                  <h1>Genesis Team Tokens</h1>
                  <h5>Fractional Units of Team NFT</h5>
                </Card27>
                <div >
                  {/* Display "North" tokens */}
                  <Card27 className="">
                  <h2>North</h2>
                  <Card29 className="card-info29">
                  {northTokenIds.map((tokenId) => (
                    <Card11 className="card11 p " key={tokenId}>
                      <Card30 className="">
                      {itemsObject[tokenId]?.name && web3.utils.isHexStrict(itemsObject[tokenId]?.name) 
                        ? <h3>{web3.utils.hexToAscii(itemsObject[tokenId].name).replace(/\u0000/g, '')}</h3>
                        : null}
                      </Card30>
                      <img src={uriValues[tokenId]} alt="" />
                      <Card28 className="">
                      <h6>My Team Points: {formatPoints(teamPointsBalanceMap[tokenId]).toString()}</h6> 
                        <form onSubmit={(e) => updatePoints(e, accounts[0], tokenId)}>
                            <button type="submit" className="button">Update Points</button>                    
                          </form>                  
                        <p>Token ID: {itemsObject[tokenId]?.tokenId}</p>
                        <p>Fans: {tokenHolderCounts[tokenId]}</p>
                        <p>Record: {winLossCounts[tokenId]?.winCount}-{winLossCounts[tokenId]?.lossCount}</p>
                        {itemsObject[tokenId]?.city && web3.utils.isHexStrict(itemsObject[tokenId]?.city) 
                        ? <p>{web3.utils.hexToAscii(itemsObject[tokenId].city).replace(/\u0000/g, '')}</p>
                        : null}
                        {itemsObject[tokenId]?.division && web3.utils.isHexStrict(itemsObject[tokenId]?.division) 
                        ? <p>{web3.utils.hexToAscii(itemsObject[tokenId].division).replace(/\u0000/g, '')}</p>
                        : null}
                        <p>Decimals: {itemsObject[tokenId]?.decimalPlaces}</p>
                        <Card30 className="">
                        <h6>Balance: {formatBalance(tokenBalances[tokenId])}</h6>
                        </Card30>
                        <Card30 className="">
                        <form onSubmit={e => (buyTeamToken(e, tokenId))}>
                          <p>Tokens Available</p>
                          <h6> {formatBalance(marketBalances[tokenId])}</h6>
                        <p>Price: {formatBalanceEth(marketObject[tokenId]?.price)} Matic</p>
                        <div className="card11">
                        <input type="text" className="form-control" id="amount" />
                        </div>
                        <button type="submit" className="button2">Buy</button>
                        </form>
                        </Card30>
                      </Card28>
                    </Card11>
                  ))}
                  </Card29>
                  </Card27>
                  {/* Display "South" tokens */}
                  <Card27 className="">
                  <h2>South</h2>
                  <Card29 className="card-info29">
                  {southTokenIds.map((tokenId) => (
                    <Card11 className="card11 p " key={tokenId}>
                    <Card30 className="">
                    {itemsObject[tokenId]?.name && web3.utils.isHexStrict(itemsObject[tokenId]?.name) 
                      ? <h3>{web3.utils.hexToAscii(itemsObject[tokenId].name).replace(/\u0000/g, '')}</h3>
                      : null}
                    </Card30>
                    <img src={uriValues[tokenId]} alt="" />
                    <Card28 className="">
                    <h6>My Team Points: {formatPoints(teamPointsBalanceMap[tokenId]).toString()} </h6>   
                        <form onSubmit={(e) => updatePoints(e, accounts[0], tokenId)}>
                            <button type="submit" className="button">Update Points</button>                    
                          </form>                  
                      <p>Token ID: {itemsObject[tokenId]?.tokenId}</p>
                      <p>Fans: {tokenHolderCounts[tokenId]}</p>
                      <p>Record: {winLossCounts[tokenId]?.winCount}-{winLossCounts[tokenId]?.lossCount}</p>
                      {itemsObject[tokenId]?.city && web3.utils.isHexStrict(itemsObject[tokenId]?.city) 
                      ? <p>{web3.utils.hexToAscii(itemsObject[tokenId].city).replace(/\u0000/g, '')}</p>
                      : null}
                      {itemsObject[tokenId]?.division && web3.utils.isHexStrict(itemsObject[tokenId]?.division) 
                      ? <p>{web3.utils.hexToAscii(itemsObject[tokenId].division).replace(/\u0000/g, '')}</p>
                      : null}
                      <p>Decimals: {itemsObject[tokenId]?.decimalPlaces}</p>
                      <Card30 className="">
                      <h6>Balance: {formatBalance(tokenBalances[tokenId])}</h6>
                      </Card30>
                      <Card30 className="">
                      <form onSubmit={e => (buyTeamToken(e, tokenId))}>
                        <p>Tokens Available</p>
                        <h6> {formatBalance(marketBalances[tokenId])}</h6>
                      <p>Price: {formatBalanceEth(marketObject[tokenId]?.price)} Matic</p>
                      <div className="card11">
                      <input type="text" className="form-control" id="amount" />
                      </div>
                      <button type="submit" className="button2">Buy</button>
                      </form>
                      </Card30>
                    </Card28>
                  </Card11>
                ))}
                  </Card29>
                  </Card27>




                  {/* Display "East" tokens */}
                  {/* <Card27 className="">
                  <h2>East</h2>
                  <Card29 className="card-info29">
                  {eastTokenIds.map((tokenId) => (
                    <Card11 className="card11 p " key={tokenId}>
                    <img src={uriValues[tokenId]} alt="" />
                    <Card28 className="">
                    <h6>My Team Points: {formatPoints(teamPointsBalanceMap[tokenId]).toString()} </h6>   
                    <Card30 className="">

                    {itemsObject[tokenId]?.name && web3.utils.isHexStrict(itemsObject[tokenId]?.name) 
                      ? <h3>{web3.utils.hexToAscii(itemsObject[tokenId].name).replace(/\u0000/g, '')}</h3>
                      : null}

                    </Card30>
                      <p>Token ID: {itemsObject[tokenId]?.tokenId}</p>
                      <p>Fans: {tokenHolderCounts[tokenId]}</p>
                      <p>Record: {winLossCounts[tokenId]?.winCount}-{winLossCounts[tokenId]?.lossCount}</p>
                      {itemsObject[tokenId]?.city && web3.utils.isHexStrict(itemsObject[tokenId]?.city) 
                      ? <p>{web3.utils.hexToAscii(itemsObject[tokenId].city).replace(/\u0000/g, '')}</p>
                      : null}
                      {itemsObject[tokenId]?.division && web3.utils.isHexStrict(itemsObject[tokenId]?.division) 
                      ? <p>{web3.utils.hexToAscii(itemsObject[tokenId].division).replace(/\u0000/g, '')}</p>
                      : null}
                      <p>Decimals: {itemsObject[tokenId]?.decimalPlaces}</p>
                      <Card30 className="">
                      <h6>Balance: {formatBalanceEth(tokenBalances[tokenId])}</h6>
                      </Card30>
                      <Card30 className="">
                      <form onSubmit={e => (buyTeamToken(e, tokenId))}>
                        <p>Tokens Available</p>
                        <h6> {formatBalanceEth(marketBalances[tokenId])}</h6>
                      <p>Price: {formatBalanceEth(marketObject[tokenId]?.price)} Matic</p>
                      <div className="card11">
                      <input type="text" className="form-control" id="amount" />
                      </div>
                      <button type="submit" className="button">Buy</button>
                      </form>
                      </Card30>
                    </Card28>
                  </Card11>
                ))}
                  </Card29>
                  </Card27>
 */}

                  {/* Display "West" tokens */}
                  {/* <Card27 className="">
                  <h2>West</h2>
                  <Card29 className="card-info29">
                  {westTokenIds.map((tokenId) => (
                    <Card11 className="card11 p " key={tokenId}>
                    <img src={uriValues[tokenId]} alt="" />
                    <Card28 className="">
                    <h6>My Team Points: {formatPoints(teamPointsBalanceMap[tokenId]).toString()} </h6>   
                    <Card30 className="">

                    {itemsObject[tokenId]?.name && web3.utils.isHexStrict(itemsObject[tokenId]?.name) 
                      ? <h3>{web3.utils.hexToAscii(itemsObject[tokenId].name).replace(/\u0000/g, '')}</h3>
                      : null}

                    </Card30>
                      <p>Token ID: {itemsObject[tokenId]?.tokenId}</p>
                      <p>Fans: {tokenHolderCounts[tokenId]}</p>
                      <p>Record: {winLossCounts[tokenId]?.winCount}-{winLossCounts[tokenId]?.lossCount}</p>
                      {itemsObject[tokenId]?.city && web3.utils.isHexStrict(itemsObject[tokenId]?.city) 
                      ? <p>{web3.utils.hexToAscii(itemsObject[tokenId].city).replace(/\u0000/g, '')}</p>
                      : null}
                      {itemsObject[tokenId]?.division && web3.utils.isHexStrict(itemsObject[tokenId]?.division) 
                      ? <p>{web3.utils.hexToAscii(itemsObject[tokenId].division).replace(/\u0000/g, '')}</p>
                      : null}
                      <p>Decimals: {itemsObject[tokenId]?.decimalPlaces}</p>
                      <Card30 className="">
                      <h6>Balance: {formatBalanceEth(tokenBalances[tokenId])}</h6>
                      </Card30>
                      <Card30 className="">
                      <form onSubmit={e => (buyTeamToken(e, tokenId))}>
                        <p>Tokens Available</p>
                        <h6> {formatBalanceEth(marketBalances[tokenId])}</h6>
                      <p>Price: {formatBalanceEth(marketObject[tokenId]?.price)} Matic</p>
                      <div className="card11">
                      <input type="text" className="form-control" id="amount" />
                      </div>
                      <button type="submit" className="button">Buy</button>
                      </form>
                      </Card30>
                    </Card28>
                  </Card11>
                ))}
                  </Card29>
                  </Card27> */}


                </div>
              </div>
            </div>
          </Card22>
        </Card21>
      </div>
    </div>
  );
};
export default SponsorProduct;
