import React, {useEffect, useState} from "react";
import '../imageStyle/logo.css'
import '../imageStyle/logo4.css'
import Card21 from '../shared/components/UIElements/Card21';
import Card22 from '../shared/components/UIElements/Card22';
import Card23 from '../shared/components/UIElements/Card23';
import Card20 from '../shared/components/UIElements/Card20';
import Card5 from '../shared/components/UIElements/Card5';
import Card6 from '../shared/components/UIElements/Card6';
import Card11 from '../shared/components/UIElements/Card11';
import '../shared/components/FormElements/Button.css';
import './SponsorItem.css';
import '../places/components/ProfileItem.css';
import {formatPoints, formatBalanceEth, formatBalance } from './DEX/helpers'

const CreateTokens = ({web3, accounts, contracts
    // , admin
  }) => {

const [pointsBal, setPointsBal] = useState(0);

// React function to add a token
async function addToken(e) {
  e.preventDefault();

  const tokenId = parseInt(e.target.elements[0].value);
  const name = e.target.elements[1].value;
  const decimalPlaces = parseInt(e.target.elements[2].value);
  const rate = parseInt(e.target.elements[3].value);
  const pointsCost = parseInt(e.target.elements[4].value);

  try {
      const receipt = await contracts.marketplace.methods.addToken(tokenId, name, decimalPlaces, rate, pointsCost)
          .send({
              from: accounts[0]
          });

      if (receipt.status) {
          console.log(`Token with ID ${tokenId} added successfully!`);
          alert('Token added successfully!');
      } else {
          console.error("Transaction failed!", receipt);
          alert('Failed to add token.');
      }
  } catch (error) {
      console.error("Error adding token:", error);
      alert('Error adding token. Please check the console.');
  }
}

// React function to add an NFT
async function addNFT(e) {
  e.preventDefault();

  const tokenId = parseInt(e.target.elements[0].value);
  const name = e.target.elements[1].value;
  const uri = e.target.elements[2].value;
  const team = e.target.elements[3].value;
  const releaseTime = parseInt(e.target.elements[4].value);
  const price = parseInt(e.target.elements[5].value);
  const pointsCost = parseInt(e.target.elements[6].value);

  try {
      const receipt = await contracts.marketplace.methods.addNFT(tokenId, name, uri, team, releaseTime, price, pointsCost)
          .send({
              from: accounts[0]
          });

      if (receipt.status) {
          console.log(`NFT with ID ${tokenId} added successfully!`);
          alert('NFT added successfully!');
      } else {
          console.error("Transaction failed!", receipt);
          alert('Failed to add NFT.');
      }
  } catch (error) {
      console.error("Error adding NFT:", error);
      alert('Error adding NFT. Please check the console.');
  }
}


const getPointsBal = async (accountAddress) => {
  try {
    const fetchedPoints = await contracts.marketplace.methods.getPointsBalance(accountAddress).call();
    setPointsBal(fetchedPoints);
  } catch (error) {
    console.error("Error fetching balance:", error);
  }
};

  useEffect(() => {
    
    console.log(pointsBal);
    
    getPointsBal(accounts[0]);
  }, []);

    async function setMaxMinTokenBuyValues(e) {
      e.preventDefault();

      // Assuming you've set up a form with 3 input fields in the order of _maxBuy, _minBuy, and _maxWhiteList.
      const _maxBuy = e.target.elements[0].value;
      const _minBuy = e.target.elements[1].value;
      const _maxWhiteList = e.target.elements[2].value;

      try {
          const accounts = await web3.eth.getAccounts();
          await contracts.marketplace.methods.setMaxMinTokenBuy(_maxBuy, _minBuy, _maxWhiteList).send({
              from: accounts[0]
          });
          alert('Values set successfully!');
      } catch (error) {
          console.error("Error setting values:", error);
          alert('Error setting values. Please check the console.');
      }
    }

    async function setTokenPrice(e) {
      e.preventDefault();
  
      // Assuming you've set up a form with 2 input fields for _tokenId and _newPrice respectively.
      const _tokenId = e.target.elements[0].value;
      const _newPrice = e.target.elements[1].value;
  
      try {
          const accounts = await web3.eth.getAccounts();
          await contracts.marketplace.methods.setPrice(_tokenId, _newPrice).send({
              from: accounts[0]
          });
          alert('Price set successfully!');
      } catch (error) {
          console.error("Error setting price:", error);
          alert('Error setting price. Please check the console.');
      }
  }
  

    async function setToken(e) {
    e.preventDefault();
    const id = parseInt(e.target.elements[0].value);    
    const cap = web3.utils.toWei((parseInt(e.target.elements[1].value)).toString(), 'ether');  
    const royaltyAddress = e.target.elements[2].value;    
    const hasRoyalty = e.target.elements[3].checked;
    if (isNaN(id) || isNaN(cap)) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
    console.log(id, cap);
    try {
      await contracts.sportsClub.methods
        .setToken(id, cap, royaltyAddress, hasRoyalty)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('70', 'gwei'), // Specify the gas price in gwei
          gas: 600000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
            });
          console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function mint(e) {
    e.preventDefault();
    const account = e.target.elements[0].value;
    const id = parseInt(e.target.elements[1].value);    
    // const amount = web3.utils.toBN((parseInt(e.target.elements[2].value)).toString());
    const amount = web3.utils.toWei((parseInt(e.target.elements[2].value)).toString(), 'ether');
    const name = e.target.elements[3].value;
    const city = e.target.elements[4].value;
    const division = e.target.elements[5].value;
    const uri = e.target.elements[6].value;
    const decimals = parseInt(e.target.elements[7].value);    
    const data = e.target.elements[8].value;

console.log(account, id, amount, name, city, uri, decimals, data);
    // Check if 'id' is a valid integer
    if (isNaN(id) || isNaN(decimals) | isNaN(amount)) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .mint(account, id, amount, name, city, division, uri, decimals, data)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('75', 'gwei'), // Specify the gas price in gwei
          gas: 650000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
                  });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function mintNFT(e) {
    e.preventDefault();
    const account = e.target.elements[0].value;
    const amount = parseInt(e.target.elements[1].value);
    const uri = e.target.elements[2].value;
console.log(account, amount, uri);
    // Check if 'id' is a valid integer
    if (isNaN(amount)) {
      console.error('Invalid input. Please enter a valid integer for ID.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .mintNFT(account, amount, uri)
        .send({from: accounts[0],
          gasPrice: web3.utils.toWei('75', 'gwei'), // Specify the gas price in gwei
          gas: 650000, // Specify the gas limit (Y is the gas limit value)
                    // , gas: 600000
                  });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }


  async function safeTransferFrom(e) {
    e.preventDefault();
    const address = e.target.elements[0].value;
    const id = parseInt(e.target.elements[1].value);
    const amountInput = e.target.elements[2].value;
    const amount = web3.utils.toWei(amountInput, 'ether'); // Convert to Wei
    const data = e.target.elements[3].value;
  
    // Validate id and amount
    if (isNaN(id) || isNaN(parseFloat(amountInput))) {
      console.error('Invalid input. Please enter valid numbers for ID and amount.');
      return;
    }
  
    try {
      await contracts.sportsClub.methods
        .safeTransferFrom(accounts[0], address, id, amount, data)
        .send({
          from: accounts[0],
          gasPrice: web3.utils.toWei('100', 'gwei'), // Specify the gas price in gwei
          gas: 1000000, // Specify the gas limit (Y is the gas limit value)
        });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
  }

  
  async function safeBatchTransferFrom(e) {
    e.preventDefault();
    const address = e.target.elements[0].value;
    const ids = e.target.elements[1].value.split(',').map(Number); // Parse input as integers

    // Convert amounts to Wei individually for each number
    const amountsInput = e.target.elements[2].value.split(',');
    const amounts = amountsInput.map(amount => web3.utils.toWei(String(amount), 'ether'));

    const data = e.target.elements[3].value;
    console.log("ids:", ids);
    console.log("amounts:", amounts);
    
    // Validate id and amount
    if (!ids.every(Number.isInteger) || !amounts.every(value => typeof value === "string")) {
      console.error('Invalid input. Please enter valid numbers for ID and amount.');
      return;
  }
    
    try {
      await contracts.sportsClub.methods
        .safeBatchTransferFrom(accounts[0], address, ids, amounts, data)
        .send({
          from: accounts[0],
          gasPrice: web3.utils.toWei('200', 'gwei'), // Specify the gas price in gwei
          gas: 2000000, // Specify the gas limit (Y is the gas limit value)
        });
      console.log('Transaction successful.');
    } catch (error) {
      console.error('Error:', error);
    }
}

  async function updateRewards(e) {
    e.preventDefault();
    await contracts.sportsClub.methods
      .updateReward()
      .send({from: accounts[0]});
  }

  return (
    <div>
  <div className="sponsor-item">

  <Card21 className="sponsor-item__content">
  <Card23 className="card-info19">
  <h2>Points</h2> 
  <h3>{formatPoints(pointsBal).toString()}</h3>

  </Card23>
    <Card22 >
        <div className='logo4-wrapper' > 
      <Card23 className="card-info19">
          {/* <img src={logo} alt='logo4'/> */}
      {/* <h2>Active Account: {accounts[0]}</h2>
      <h2>Your CFC Balance:  */}
        {/* {web3.utils.fromWei((userCfcBalance).toString(), 'ether')} */}
        {/* </h2> */}

        <form onSubmit={e => (addToken(e))}>
        <h2>Add Token</h2>
        <div className="col-sm-8">
        <h3>Token ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Name</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Decimal Places</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Rate</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Points Cost</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Add Token</button>
        </form>
        <br></br>

        <form onSubmit={e => (setMaxMinTokenBuyValues(e))}>
        <h2>Max Min Whitelist</h2>
        <div className="col-sm-8">
        <h3>Maximum</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Minimum</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>Max WhiteList</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Set Max Min Whitelist</button>
        </form>
        <br></br>

        <form onSubmit={e => (setTokenPrice(e))}>
        <h3>Set Token Price</h3>
        <div className="col-sm-8">
        <h3>ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Price</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Set Token</button>
        </form>
        <br></br>



        <form onSubmit={e => (safeTransferFrom(e))}>
        <div className="col-sm-8">
        <h3>Address</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Token ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Amount</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Data</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Transfer Token</button>
        </form>
        <br></br>

        <form onSubmit={e => (safeBatchTransferFrom(e))}>
        <div className="col-sm-8">
        <h3>Address</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Token IDs</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Amounts</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Data</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">BatchTransfer Token</button>
        </form>
        <br></br>


        <form onSubmit={e => (setToken(e))}>
        <div className="col-sm-8">
        <h3>ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Cap/Max Supply</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>Royalty Address</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <div className="col-sm-8">
        <h3>Is Royalty?</h3>
        <input type="checkbox" className="form-control" id="hasRoyalty" />
        </div>
        <button type="submit" className="button">Set Token</button>
        </form>
        <br></br>


        <form onSubmit={e => (mint(e))}>
        <div className="col-sm-8">
        <h3>Account</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>ID</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>amount</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>Name</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>City</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>Division</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>URI</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>Decimals</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>Data</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">Mint Team</button>
        </form>

        <form onSubmit={e => (mintNFT(e))}>
        <div className="col-sm-8">
        <h3>Account</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <div className="col-sm-8">
        <h3>amount</h3>
        <input type="text" className="form-control" id="amount" />
        </div>
        <br></br>
        <h3>URI</h3>
        <div className="col-sm-8">
        <input type="text" className="form-control" id="amount" />
        </div>
        <button type="submit" className="button">MintNFT</button>
        </form>
      </Card23>
      
      <Card23 className="card-info19">
          {/* <img src={logo} alt='logo4'/> */}
          <h2>Active Account: {accounts[0]}</h2>
      <h2>
        {/* Reward Points: {(formatPoints(rewardsBalance))} */}
        </h2>
      </Card23>

            {/* <Card20 className="card-info20">
                <Card11 className="card11">
                  <img src={Pirate} alt=""/>
                  <h2>{tokens[0].name}</h2>
                  <h6>TokenId {tokens[0].tokenId}</h6>
                  <h4>  My Balance: {formatBalanceEth(token1)}</h4>
                  <h4>Record</h4>
                    {record1 && (
                      <p>
                        Wins: {record1[0]}<br />
                        Losses: {record1[1]}
                      </p>
                    )}                        
                    <h6>City: {tokens[0].city}</h6>
                  <h6>Supportors {supportors1}</h6>
                  </Card11>
                  <Card11 className="card11">
                  <img src={Ninja} alt=""/>
                      <h2>{tokens[1].name}</h2>
                      <h6>TokenId {tokens[1].tokenId}</h6>
                      <h4>  My Balance: {formatBalanceEth(token2)}</h4>
                      <h4>Record</h4>
                    {record2 && (
                      <p>
                        Wins: {record2[0]}<br />
                        Losses: {record2[1]}
                      </p>
                    )}                        

                  <h6>City: {tokens[1].city}</h6>
                  <h6>Supportors {supportors2}</h6>
                  </Card11>

                  <Card11 className="card11">
                  <img src={jaguar} alt=""/>
                      <h2>{tokens[2].name}</h2>
                  <h6>TokenId {tokens[2].tokenId}</h6>
                      <h4>  My Balance: {formatBalanceEth(token3)}</h4>
                      <h4>Record</h4>
                    {record3 && (
                      <p>
                        Wins: {record3[0]}<br />
                        Losses: {record3[1]}
                      </p>
                    )}                        

                  <h6>City: {tokens[2].city}</h6>
                  <h6>Supportors {supportors3}</h6>
                  
                  
                  </Card11>

                  <Card11 className="card11">
                  <img src={Pirate} alt=""/>
                  <h2>{tokens[3].name}</h2>
                  <h6>TokenId {tokens[3].tokenId}</h6>
                  <h4>  My Balance: {formatBalanceEth(token4)}</h4>
                  <h4>Record</h4>
                    {record4 && (
                      <p>
                        Wins: {record4[0]}<br />
                        Losses: {record4[1]}
                      </p>
                    )}                        
                    <h6>City: {tokens[3].city}</h6>
                  <h6>Supportors {supportors4}</h6>
                  </Card11>

                  <Card11 className="card11">
                  <img src={Ninja} alt=""/>
                      <h2>{tokens[4].name}</h2>
                      <h6>TokenId {tokens[4].tokenId}</h6>
                      <h4>  My Balance: {formatBalanceEth(token5)}</h4>
                      <h4>Record</h4>
                    {record5 && (
                      <p>
                        Wins: {record5[0]}<br />
                        Losses: {record5[1]}
                      </p>
                    )}                        

                  <h6>City: {tokens[4].city}</h6>
                  <h6>Supportors {supportors5}</h6>
                  
                  </Card11>

                  <Card11 className="card11">
                  <img src={jaguar} alt=""/>
                      <h2>{tokens[2].name}</h2>
                  <h6>TokenId {tokens[2].tokenId}</h6>
                      <h4>  My Balance: {formatBalanceEth(token3)}</h4>
                      <h4>Record</h4>
                    {record3 && (
                      <p>
                        Wins: {record3[0]}<br />
                        Losses: {record3[1]}
                      </p>
                    )}                        

                  <h6>City: {tokens[2].city}</h6>
                  <h6>Supportors {supportors3}</h6>
                  
                  
                  </Card11>


              </Card20 >             */}
        </div>
      </Card22>
  </Card21>
  </div> 
</div>
);
};
export default CreateTokens;
