import React from 'react';

import './Card21.css';

const Card21 = props => {
  return (
    <div className={`card21 ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default Card21;
