import React, {useEffect, useState} from "react";
import logo from '../images/logo.png';
import football from '../images/football.jpeg';
import '../imageStyle/logo.css'
import '../imageStyle/logo4.css'
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import Card21 from '../shared/components/UIElements/Card21';
import Card22 from '../shared/components/UIElements/Card22';
import Card23 from '../shared/components/UIElements/Card23';
import Card27 from '../shared/components/UIElements/Card27';
import Card28 from '../shared/components/UIElements/Card28';
import Card30 from '../shared/components/UIElements/Card30';
import Card18 from '../shared/components/UIElements/Card18';
import Card19 from '../shared/components/UIElements/Card19';
// import Price from './Price';
import '../imageStyle/logo2.css'
// import drizzleOptions from "./drizzleOptions";
import Card4 from '../shared/components/UIElements/Card4';
import Card20 from '../shared/components/UIElements/Card20';
import Card5 from '../shared/components/UIElements/Card5';
import Card6 from '../shared/components/UIElements/Card6';
import Card11 from '../shared/components/UIElements/Card11';
import '../shared/components/FormElements/Button.css';
import './SponsorItem.css';
import '../places/components/ProfileItem.css';
import {formatPoints, formatBalanceEth, formatBalance } from './DEX/helpers'




// TODO
// Side Note: Solidity returns objects so returning objects with react
// Make mintNFT() function
// make changeSeason() setRoyalties() toggleTransferPause() transferRoyalties()

const NFTItems = ({web3, accounts, contracts
  // , admin
}) => {
  
  const [nftIds, setNftIds] = useState([]);
  const [uriNft, setUriNft] = useState({});

  const [metadata, setMetadata] = useState(null);

  

  const [nftData, setNftData] = useState({});


  // const marketObject = {};
  // fetchedMarketArray.forEach((tokens) => {
  //   marketObject[tokens.tokenId] = {
  //     tokenId: tokens.tokenId,
  //     name: tokens.name,
  //     decimalPlaces: tokens.decimalPlaces,
  //     price: tokens.price,
  //     pointsCost: tokens.pointsCost,
  //   };
  // });
  // setMarketObject(marketObject);




  useEffect(() => {
    async function fetchSupply() {
        try {
          const counts = {};

          for (const nftId of nftIds) {
            const countsObject = await contracts.sportsClub.methods.totalSupply(nftId).call();
            counts[nftId] = {
              totalSupplyValue: countsObject[0],
              // tokenCapValue: countsObject[1],
            };
          }
          // console.log(counts)
          // Set the winLossCounts in state
          setNftData(counts);
      

          console.log(counts);
          console.log(nftData);
        } catch (error) {
          console.error('Error connecting to MetaMask:', error);
        }
    }
    fetchSupply()
  }, [nftIds]);






  useEffect(() => {
    async function fetchMetadata() {
      try {
        // Fetch metadata for each NFT individually
        const metadataPromises = nftIds.map(async (nftId) => {
          const response = await fetch(uriNft[nftId]);
          if (!response.ok) {
            throw new Error(`Failed to fetch metadata for NFT ID ${nftId}`);
          }
          const data = await response.json();
          return { [nftId]: data };
        });

        const metadataResults = await Promise.all(metadataPromises);
        const metadataObject = Object.assign({}, ...metadataResults);
        setMetadata(metadataObject);
        console.log(metadataObject);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    }

    fetchMetadata();
  }, [uriNft, nftIds]);




  useEffect(() => {
    async function fetchData() {
      try {
        // Call the getNftIds function
        const nftIdsArray = await contracts.sportsClub.methods.getNftIds().call();

        // Update the state with the NFT IDs
        setNftIds(nftIdsArray);

        // console.log(nftIds[0]);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchUris = async () => {
      try {
        const uriPromises = nftIds.map(async (nftId) => {
          // Replace 'yourContractInstance' with your actual contract instance
          const uri = await contracts.sportsClub.methods.uri(nftId).call();
          return { [nftId]: uri }; // Map the URI to its corresponding token ID
        });
  
        const uriResults = await Promise.all(uriPromises);
        const uriObject = Object.assign({}, ...uriResults); // Merge the URI results into an object
        console.log(uriResults);
        console.log(uriObject)
        console.log(uriNft)

        setUriNft(uriObject);
          } catch (error) {
        console.error('Error fetching URIs:', error);
      }
    };
  
    // Call the fetchUris function
    fetchUris();
  }, [
    nftIds, contracts.sportsClub
  ]);
  


  return (

    <div>
      <div className="sponsor-item">

      <Card21 className="sponsor-item__content">
      {/* <Card23 className="card-info19">
      <h2>All Current Teams</h2> 
        <div>
        <h2>NFTs</h2>
          
        </div>  
      </Card23> */}
        <Card22 >
        {/* <img src={football} alt=''/> */}

            <div className='logo4-wrapper' > 
            <Card23 className="card-info19">
            <Card27 className="card-info27">
              {/* <img src={logo} alt='logo4'/> */}
          {/* <h2>Active Account: {accounts[0]}</h2> */}
          <h2>Testing Examples </h2>
          <h2>NFT Marketplace coming soon!
            {/* {web3.utils.fromWei((userCfcBalance).toString(), 'ether')} */}
            </h2>
            <p>Collect and upgrade your NFTs!</p>

            </Card27>
            </Card23>
          
          {/* <Card23 className="card-info19">
              <img src={logo} alt='logo4'/>
              <h2>Active Account: {accounts[0]}</h2>
          <h2>
            Reward Points: {(formatPoints(rewardsBalance))}
            </h2>
          </Card23> */}

                <Card20 className="card-info20">




<div>
      <div className="sponsor-item">
        {/* <h2>Player NFTs</h2> */}
        <div className="card-info20">
          {nftIds.map((nftId) => (
            <Card11 className="card11" key={nftId}>
              {/* <p>URI: {uriNft[nftId]}</p> */}
              {metadata[nftId] ? (
                <div>
                  <p>NFT ID: {nftId}</p>
                  <p>{metadata[nftId].name}</p>
                <img src={metadata[nftId].image} alt="NFT" />
                <Card28 className="card28">
                <h6>Total Supply: {nftData[nftId]?.totalSupplyValue}</h6>
                  <p>Rarity: {metadata[nftId].rarity}</p>
                  <p>Description: {metadata[nftId].description}</p>
                  {/* Add more metadata fields as needed */}

                  </Card28>
                  {/* <Card30 className="">
                  <form onSubmit={e => (buyNFT(e, nftId))}>
                    <p>Tokens Available</p>
                    <h6> {formatBalanceEth(marketBalances[nftId])}</h6>
                  <p>Price: {formatBalanceEth(marketObject[nftId]?.price)} Matic</p>
                  <div className="card11">
                  <input type="text" className="form-control" id="amount" />
                  </div>
                  <button type="submit" className="button">Buy</button>
                  </form>
                  </Card30> */}
                </div>
              ) : (
                <p>Loading metadata...</p>
              )}
           </Card11>
          ))}
              </div>            
        {/* Your existing code */}
      </div>
    </div>



              </Card20 >            
            </div>
          </Card22>
      </Card21>
      </div> 
    </div>
  );
};
export default NFTItems;

