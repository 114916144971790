import Web3 from 'web3';
import SportsClub from '../contracts/SportsClub.json';
// import SportsClubABI from '../contracts/SportsClubABI.json';
import detectEthereumProvider from '@metamask/detect-provider';
// import Dex1155 from '../contracts/Dex1155.json';

import Marketplace from '../contracts/Marketplace.json';
// import Fantasy from '../contracts/Fantasy.json';
import Sport from '../contracts/Sport.json';
// import ERC20Abi from './ERC20Abi.json';

const getWeb3 = () =>
    new Promise( async (resolve, reject) => {
    let provider = await detectEthereumProvider();
    if(provider) {
      await provider.request({ method: 'eth_requestAccounts' });
    try {
      const web3 = new Web3(window.ethereum);
      resolve(web3);
    } catch(error) {
      reject(error);
    }

    } reject('Install Metamask');

});
const getContracts = async web3 => {
  const networkId = await web3.eth.net.getId();
  const deployedNetwork = Marketplace.networks[networkId];
  const deployedNetworkContract = SportsClub.networks[networkId];
  // const deployedNetworkClubLib = ClubLib.networks[networkId];
  const deployedNetworkSport = Sport.networks[networkId];
  const marketplace = new web3.eth.Contract(
    Marketplace.abi,
    deployedNetwork && deployedNetwork.address
  );
  // const deployedNetworkDex = Dex1155.networks[networkId];
  // const dex = new web3.eth.Contract(
  //   Dex1155.abi,
  //   deployedNetworkDex && deployedNetworkDex.address
  // );
  const sportsClub = new web3.eth.Contract(
    SportsClub.abi,
    deployedNetworkContract && deployedNetworkContract.address
  );
  // const clubLib = new web3.eth.Contract(
  //   ClubLib.abi,
  //   deployedNetworkClubLib && deployedNetworkClubLib.address
  // );
  const sport = new web3.eth.Contract(
    Sport.abi,
    deployedNetworkSport && deployedNetworkSport.address
  );
  // const tokens = await dex.methods.getTokenIds().call();
  // const allTokens = tokens.reduce((acc, token) => ({
  //   ...acc,
  //   [web3.utils.fromAscii(token.tokenId)]: new web3.eth.Contract(
  //     TokenContract.abi,
  //     token.tokenId
  //   )
  // }), {});

  return { 
    marketplace,
    //  dex,
     sportsClub,
    //  clubLib
     sport
    //  , ...allTokens 
    // , tokens
    };
}

export { getWeb3
  , getContracts 
};
