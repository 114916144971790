import React from 'react';

import './Card29.css';

const Card29 = props => {
  return (
    <div className={`card29 ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default Card29;
