import React from 'react';

import './Card12.css';

const Card12 = props => {
  return (
    <div className={`card12 ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default Card12;
