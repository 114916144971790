import React, { createContext, useContext, useState, useEffect } from 'react';

const SportsDataContext = createContext();

export const SportsDataProvider = ({ children, contracts }) => {
  const [tokenHolderCounts, setTokenHolderCounts] = useState({});
  const [uriValues, setUriValues] = useState({});
  const [winLossCounts, setWinLossCounts] = useState({});
  const [itemsObject, setItemsObject] = useState({});
  const [marketObject, setMarketObject] = useState({});
  const [tokenIds] = useState([1, 2, 3, 4, 5, 6]);

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch token holder counts, URIs, win/loss counts, and items concurrently
        
        const [tokenHolderCounts, uriResults, winLossCounts, fetchedItemsArray, fetchedMarketArray] = await Promise.all([
          contracts
            ? Promise.all(
                tokenIds.map(async (tokenId) => {
                  const count = await contracts.sportsClub.methods.getTokenHolderCount(tokenId).call();
                  return { [tokenId]: count };
                })
              )
            : [],
          Promise.all(
            tokenIds.map(async (tokenId) => {
              const uri = await contracts.sportsClub.methods.uri(tokenId).call();
              return { [tokenId]: uri };
            })
          ),
          Promise.all(
            tokenIds.map(async (tokenId) => {
              const countsObject = await contracts.sportsClub.methods.getWinLossCount(1, tokenId).call();
              return { [tokenId]: { winCount: countsObject[0], lossCount: countsObject[1] } };
            })
          ),
          contracts
            ? contracts.sportsClub.methods.getItems().call()
            : [],
          contracts
            ? contracts.marketplace.methods.getTokens().call()
            : [],
        ]);

        // Update token holder counts
        const tokenHolderCountsObject = Object.assign({}, ...tokenHolderCounts);
        setTokenHolderCounts(tokenHolderCountsObject);

        // Update URIs
        const uriObject = Object.assign({}, ...uriResults);
        console.log(uriResults);
        setUriValues(uriObject);

        // Update win/loss counts
        const winLossCountsObject = Object.assign({}, ...winLossCounts);
        setWinLossCounts(winLossCountsObject);

        // Update items
        const itemsObject = {};
        fetchedItemsArray.forEach((item) => {
          itemsObject[item.tokenId] = {
            tokenId: item.tokenId,
            name: item.name,
            city: item.city,
            division: item.division,
            decimalPlaces: item.decimalPlaces,
          };
        });
        setItemsObject(itemsObject);
        console.log(itemsObject);

        // Update items
        const marketObject = {};
        fetchedMarketArray.forEach((tokens) => {
          marketObject[tokens.tokenId] = {
            tokenId: tokens.tokenId,
            name: tokens.name,
            decimalPlaces: tokens.decimalPlaces,
            price: tokens.price,
            pointsCost: tokens.pointsCost,
          };
        });
        setMarketObject(marketObject);
        console.log(marketObject);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchData();
  }, [contracts, tokenIds]);

  return (
    <SportsDataContext.Provider
      value={{
        tokenHolderCounts,
        uriValues,
        winLossCounts,
        itemsObject,
        marketObject,
      }}
    >
      {children}
    </SportsDataContext.Provider>
  );
};

export const useSportsData = () => {
  const context = useContext(SportsDataContext);
  if (!context) {
    throw new Error('useSportsData must be used within a SportsDataProvider');
  }
  return context;
};
